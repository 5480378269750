const __request = require(`./__request`);

//http://192.168.0.15:8080/doc.html#/所有接口/images-controller/aptitudeSingleImageUsingPOST

const web_common_upload_aptitudeSingleImage = function (pParameter) {
  if (!pParameter) pParameter = {};
  const formData = new FormData();
  formData.append('fileUpload', pParameter);
  return __request({
    urlSuffix: 'web/common/upload/aptitudeSingleImage',
    data: formData,
    transformRequest: [function (data, headers) {
      return data;
    }],
  });
};

export default web_common_upload_aptitudeSingleImage;

