// const refund = require(`@/lib/data-service/flight/flight_domestic_buyer_white_order_refund`);
import buyer_dom_manage_refund from '@/lib/data-service/flight/flight_domestic_buyer_white_order_refund'
import refundSubmit from "@/lib/data-service/flight/newFligthApi/buyer_dom_white_refund";
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import upLoadImg from "@/lib/data-service/default/web_common_upload_aptitudeSingleImage";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
    data() {
        return {
            detailData: {},
            orderNo: "",
            orderStatus: "",
            orderStatusText: "",
            flightType: "1",
            passengers: [],
            dishonourType: "请选择",
            dishonourId: [],
            remark: "",
            loading: true,
            //formData: null,

            tableData: [],
            segmentList: {},
            options: [
                {
                    value: 1,
                    label: "自愿退票（按客规收取退票手续费）"
                },
                {
                    value: 2,
                    label: "【非自愿退票】航班延误或取消"
                },
                /*{
                  value: 3,
                  label: "升舱换开"
                },*/
                {
                    value: 4,
                    label: "【非自愿退票】因病无法乘机"
                },
                {
                    value: 5,
                    label: "其他退票情况（属于自愿或非自愿，请备注原因）"
                }
            ],
            refundSecondType: [
                {
                    value: 1,
                    label: '升舱申请全退；已取消编码作废行程单；同一供应商升舱换开，新票已经使用原票申请全退。（请备注升舱新票号）'
                },
                {
                    value: 2,
                    label: '名字错误，换开重出，新票已使用；已取消编码作废行程单，申请全退。（请备注新票票号）'
                },
                {
                    value: 3,
                    label: '客票当日已作废，请供应商按废票处理'
                },
                {
                    value: 4,
                    label: '同一供应商重复出票（支付）申请全退，不动编码和票号'
                },
                {
                    value: 5,
                    label: '客票不退，退回多余票款'
                },
                {
                    value: 6,
                    label: '非供应商出票，不动编码和票号，全退票款'
                },
                {
                    value: 7,
                    label: '只退保险，不动编码和票号'
                },
                {
                    value: 8,
                    label: '其他（降舱、迫降、超售、差错退款）已提供证明申请全退'
                },
                {
                    value: 9,
                    label: '海航金卡会员（已作废行程单，取消PNR编码，申请全退）；（请备注会员卡号）'
                },
                {
                    value: 10,
                    label: '【以审核为准】重购客票，以提交航空公司审核为准'
                },
                {
                    value: 11,
                    label: '【以审核为准】审核有误，申请二次退款，调差补退票款'
                },
                {
                    value: 12,
                    label: '航司公布疫情政策申请退票'
                },
            ],
            radio: "",
            value: "",
            textarea: "",
            formData: {
                orderNo: '',
                // orderNo: this.detailData.orderNo,
                passengerList: [],
                refundType: null,
                otherRefundReason: null,
                remark: "",
                seatStatus: 2,
                url: [],
                batchFlag: 0
            },
            fileImg: [],

            ossClient: new OssClient(),
            estimatedCost: {
                refAllAmount:0, // 全退
                refRate: 0, // 费率
                refRealAmount:0, // 应退
                refRealFee:0 // 退票费
            },
            size:0, // 选中人数
            sumPassenger:1,// 总人数
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    async activated() {
        this.orderNo = this.$route.query.ordersNo;
        await this.GetdetailData();
        // 初始化form表单
        setTimeout(() => {
            this.reactFormData()
        }, 500)
        // this.orderStatus = this.$route.query.orderStatus
        // this.orderStatusText = this.$route.query.orderStatusText
        // detail({orderNo: this.orderNo}).then( res => {
        //   console.log(res)
        //   this.detailData = res.detail
        //   this.flightType = res.detail.flightType
        //   this.passengers = res.detail.passengers
        //   for (let i = 0; i < this.passengers.length; i++) {
        //     this.passengers[i].inputC = false
        //     for (let j = 0; j < this.passengers[i].segments.length; j++) {
        //       this.passengers[i].segments[j].inputC = false
        //     }
        //   }
        //   console.log(this)
        //   this.$forceUpdate();
        // })
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async GetdetailData() {
            let [err, res] = await awaitWrap(buyer_dom_manage_refund({ orderNo: this.orderNo }))
            if (err) {
                this.loading = false;
                this.$alert(err.data.msg, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1);
                    }
                });
                return
            }
            console.log(res);
            this.loading = false;
            this.detailData = res.refundRes;
            this.sumPassenger = res.refundRes.refundRerationList.length;
            this.estimatedCost.refAllAmount = res.refundRes.refAllAmount / this.sumPassenger;
            this.estimatedCost.refRate = res.refundRes.refRate;
            this.estimatedCost.refRealAmount = res.refundRes.refRealAmount / this.sumPassenger;
            this.estimatedCost.refRealFee = res.refundRes.refRealFee / this.sumPassenger;
            console.log(this.detailData)
        },
        getData(val) {
            this.formData = val;
        },
        getsegments(id, inputType) {
            inputType.inputC = !inputType.inputC;
            console.log(id, inputType);
            this.$forceUpdate();
        },
        submit() {
            let data = this.formData;
            if (!data.refundType)
                return this.$message({ type: "warning", message: "请选择退票类型", offset: 80 });
            if (data.passengerList.length === 0) {
                return this.$message({ type: "warning", message: "请选择航程信息", offset: 80 });
            }
            if (data.refundType === 5 && !data.otherRefundReason) {
                return this.$message({ type: "warning", message: "请选择退票理由", offset: 80 });
            }
            if ([4].indexOf(data.refundType) > -1 && (!data.url || data.url.length === 0)) {
                return this.$message({ type: "warning", message: "请上传延误/病退证明", offset: 80 });
            }
            if ([5].indexOf(data.refundType) > -1 && (!data.url || data.url.length === 0)) {
                return this.$message({ type: "warning", message: "请上传延误/病退证明", offset: 80 });
            }
            if ([2,4,5].indexOf(data.refundType) > -1 && !data.remark) {
                return this.$message({ type: "warning", message: "请填写备注", offset: 80 });
            }

            this.loading = true;
            refundSubmit(data)
                .then(res => {
                    this.loading = false;
                    if (res.code == this.SUCCESS_CODE) {
                        this.$router.push({
                            name: "air-ticket-admin-refund-order-list",
                            params: { refresh: 1 }
                        });
                        this.$message({ type: "success", message: "申请成功！", offset: 80 });
                    }
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        getDishonour() {
            // if(this.dishonourType === '请选择') {
            //   return this.$message({
            //     message: "请选择退票类型",
            //     type: "error"
            //   });
            // }
            this.dishonourId = [];
            for (let i = 0; i < this.passengers.length; i++) {
                for (let j = 0; j < this.passengers[i].segments.length; j++) {
                    if (this.passengers[i].segments[j].inputC === true) {
                        this.dishonourId.push(this.passengers[i].segments[j].airlineId);
                    }
                }
            }
            if (this.dishonourId.length === 0) {
                return this.$message({
                    message: "请选择需要退票的乘客",
                    type: "error",
                    offset: 80
                });
            }
            let lineId = this.dishonourId.join();
            let params = {
                lineId,
                orderNo: this.orderNo,
                refundType: "1",
                remark: this.remark
            };
            console.log(this.dishonourId, params);
            refund(params)
                .then(res => {
                    console.log(res);
                    this.$router.go(-1);
                })
                .catch(err => {
                    return this.$message({
                        message: err,
                        type: "error",
                        offset: 80
                    });
                });
        },

        uploadImg(file) {
            this.ossClient.multipartUpload({
                file: file.file,
            }).then((res) => {
                this.fileImg.push({ url: res.url, uid: file.file.uid });
                this.getUrl(this.fileImg);
            }).catch(() => {
            }).finally(() => {
            });
        },
        goPage(val) {
            if (!val) return;
            this.$router.push({
                name: "air-ticket-admin-international-list-detail",
                query: { ordersNo: val }
            });
        },
        selectFlight(val, index,psg) {
            this.segmentList[index] = val;
            var passengerList = (arr = []) => {
                if (!arr instanceof Array) return;
                return arr.map(item => {
                    let list = {
                        psgNo: item[0].psgId,
                        segmentNoList: item.map(items => items.segmentId)
                    };

                    return list;
                });
            };
            this.formData.passengerList = passengerList(
                Object.values(this.segmentList).filter(item => item.length)
            );
            this.size = this.formData.passengerList.length;
        },
        getUrl(val) {
            let url = val.map(item => {
                return item.url;
            });
            this.formData.url = url;
        },
        detectionImg(file) {
            var reg2 = /^(\s|\S)+(jpg|png|bmp)+$/;
            const isLt2M = file.size / 1024 < 3 * 1024;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 3M!");
                return false;
            }
        },
        removeImg(file) {
            this.fileImg.map((item, index) => {
                item.uid == file.uid ? this.fileImg.splice(index, 1) : "";
            });
            this.getUrl(this.fileImg);
        },
        reactFormData() {
            this.formData = {
                orderNo: this.detailData.orderNo,
                passengerList: [],
                refundType: null,
                otherRefundReason: null,
                remark: "",
                seatStatus: 2,
                url: [],
                batchFlag: 0
            }
        },
    }
}
